import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const UPDATE_TABLE_STRUCTURE = "getPICKINGASSIGNMENTTableStructure";
export const UPDATE_TABLE_DATA = "updatePICKINGASSIGNMENTTableData";
export const GET_SERVER_DATA = "getPICKINGASSIGNMENTTableServerData";

// mutation types
export const SET_TABLE_STRUCTURE = "setPICKINGASSIGNMENTTableStructure";
export const SET_TABLE_DATA = "setPICKINGASSIGNMENTTableData";
export const SET_TABLE_FILTER = "setPICKINGASSIGNMENTTableFilter";
export const SET_TABLE_PAGE = "setPICKINGASSIGNMENTTablePage";
export const SET_TABLE_PERPAGE = "setPICKINGASSIGNMENTTablePerPage";
export const SET_TABLE_SORTBY = "setPICKINGASSIGNMENTTableSortBy";
export const SET_TABLE_SORTORDER = "setPICKINGASSIGNMENTTableSortOrder";
export const SET_SELECTED_PICKING = "setPICKINGASSIGNMENTTableSelectedPicking";
export const SET_SERVER_DATA = "setPICKINGASSIGNMENTTableServerData";

const state = {
  table_structure: {},
  table_data: {},
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
  selected_picking: [],
  serverData: [],
};

const getters = {
  getPICKINGASSIGNMENTTableProperties(state) {
    return state.table_structure.properties;
  },

  getPICKINGASSIGNMENTTablePermissions(state) {
    return state.table_structure.permissions;
  },

  getPICKINGASSIGNMENTTableState(state) {
    return state.table_state;
  },

  getPICKINGASSIGNMENTTableHeaders(state) {
    return state.table_structure.columns;
  },

  getPICKINGASSIGNMENTTableFilters(state) {
    return state.table_structure.filters;
  },

  getPICKINGASSIGNMENTTableData(state) {
    return state.table_data;
  },

  getPICKINGASSIGNMENTTableSelectedPicking(state) {
    return state.selected_picking;
  },

  getPICKINGASSIGNMENTTableServerData(state) {
    return state.serverData;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/picking_assignment")
        .then(({ data }) => {
          const response = data;
          response.data.columns.pop(); // delete the actions column
          context.commit(SET_TABLE_STRUCTURE, response);
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },

  [UPDATE_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post(
        "/warehouse_management/picking_assignment/datatable",
        payload
      )
        .then(({ data }) => {
          context.commit(SET_TABLE_DATA, data);
          resolve(data);
          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },

  [GET_SERVER_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post(
        "/warehouse_management/picking_assignment/edit",
        payload
      ).then(({ data }) => {
        context.commit(SET_SERVER_DATA, data);
        resolve(data);
        context.commit(SET_PAGE_LOADING, false);
      });
    });
  },
};

const mutations = {
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;
  },

  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },

  [SET_TABLE_SORTBY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },

  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },

  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },

  [SET_TABLE_PERPAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },

  [SET_TABLE_SORTBY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },

  [SET_TABLE_SORTORDER](state, sortOrder) {
    state.table_state.sortOrder = sortOrder;
  },

  [SET_SELECTED_PICKING](state, data) {
    state.selected_picking = data;
  },

  [SET_SERVER_DATA](state, data) {
    state.serverData = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
